import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';

import { disablePushState } from 'utils/functions';

const useFacebookPixel = (pixelId: string) => {
  const { init } = ReactPixel;
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!pixelId) return;
    setTimeout(() => {
      init(pixelId,);
      disablePushState();
      setInitialized(true);
    }, 5000);
  }, [pixelId, init]);

  useEffect(() => {
    if (!pixelId || !initialized) {
      return;
    }
    ReactPixel.fbq('track', 'PageView');
  }, [pixelId, location.pathname, initialized]);
};

export default useFacebookPixel;
